#Header{
  width: 100%;
  padding: 1rem 0;
  position: fixed;
  z-index: 100;
  box-sizing: border-box;
  max-width: 100vw;

  background: rgba(255,255,255,0.6);
  backdrop-filter: blur(10px);

  #logo {
    background: #fff;
    padding-top: 10px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      background: #fff;
      height: 9rem;
      top: 0;
      left: 0;
      z-index: -1;
    }

    a {
      color: transparent;
      background: url('../../images/citydata_new_logo_box_white_gray_red_orange_green.png') no-repeat left;
      font-size: 6rem;
      transform: translateY(-9px);
      background-size: contain;
    }
  }

  menu {
    li {
      display: inline-block;
      margin: 0 1rem;

      &:last-child {
        margin: 0;
      }

      &.hasDropdown {
        position: relative;

        .dropdown {
          transform: translateY(3px);          
          background: #fff;
          display: none;
          margin-top: 1rem;
          position: absolute;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
          padding: 1rem 1rem;
          width: max-content;
          box-sizing: border-box;
          margin-left: 0rem;
          z-index: 1;

          .dropdown_item {
            display: block;
            margin: 1rem 0;

            a {
              line-height: 1.2;
            }

            a:hover {
              color: #73c6bd;
            }

            &.disabled {
              pointer-events: none;
              opacity: 0.5;
              color: #000;              

              a {
                color: inherit;
              }
            }
          }
        }

        &.active a.cta {
          background-color: #73c6bd;
          color: #fff;
        }

        &.languages .dropdown {
          right: 0;
        }
      }

      a {
        font-size: 2rem;
        color: #1c4362;
        transition: color .15s ease-in-out;

        &.cta {
          background-color: #fff;
          border-radius: 5px;
          line-height: 1.1;
          padding: 1rem 1rem;
          width: fit-content;

          &:hover {
            background-color: #73c6bd;
            color: #fff;
          }
        }

        &.product {
          &:before {
            display: none;
            content: "";
            height: 1em;
            width: 1em;
            font-size: 30px;
            margin-right: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: sub;
          }
          
          &.citydash:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citydash_cd_blob_2letter.png);
            display: inline-block;
          }
          &.cityflow:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/cityflow_cf_blob_2letter.png);
            display: inline-block;
          }
          &.citysim:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citysim_cs_blob_2letter.png);
            display: inline-block;
          }
          &.citycount:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citycount_cn_blob_2letter.png);
            display: inline-block;
          }
          &.cityparks:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/cityparks_cp_blob_2letter.png);
            display: inline-block;
          }
          &.citychat:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citychat_cc_blob_2letter.png);
            display: inline-block;
          }
          &.cityops:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/cityops_co_blob_2letter.png);
            display: inline-block;
          }
          &.citysdk:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/citysdk_cs_blob_2_letter.png);
            display: inline-block;
          }
          &.cityai:before {
            background-image: url(https://s3.us-west-2.amazonaws.com/citydata.media/citydata.ai/images/dist/cityai_ca_blob_2_letter.png);
            display: inline-block;
          }
        }
      }
    }
  }

  .onMobile {
    display: none;
  }

  &.isLogged {
    .SignIn {
      display: none;
    }
    .MyAccount {
      display: block;
    }
  }
}

@media screen and (max-width:1160px) {
  #Header menu li {
    margin: 0;
  }  
}

@media screen and (min-width: 1001px) {
  #Header {
    menu {
      li {
        &.hasDropdown {
          &:hover {
            .dropdown {
              display: block;
              border: solid 2px #97d5ce;
            }
        
            .cta {
              background-color: #73c6bd;
              color: #fff;
              border-bottom: solid 10px #73c6bd;
            }
          }  
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {  
  #Header {

    #logo {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        transform: none;
      }

      .onMobile {
        display: block;

        width: 1em;
        height: 1em;
        font-size: 5rem;
        background: #ff8a89;
        color: #fff;
        border-radius: 3px;        
      }
    }

    .container {
      flex-direction: column;
      align-items: start;
    }

    menu {
      width:100%;
      margin-top: 0;

      max-height: 0;
      overflow: hidden;
      transition: all .1s linear;

      padding-top: 0;
      text-align: right;
      
      &.opened {
        margin-top: 2rem;
        padding-top: 1.5rem;
        max-height: 100vh;
        overflow: scroll;
      }

      li {
        display: block;
        min-height: 5rem;
        clear: both;

        a.cta {
          float: right;
        }

        a.cta,
        &.hasDropdown .dropdown {
          background-color: transparent;
        }

        &.hasDropdown {
          .dropdown {
            position: relative;
            width: fit-content;
            text-align: right;
            
            border: solid 2px #97d5ce;
            border-top-right-radius: 0px;
            border-top-left-radius: 5px;
            margin-bottom: 2rem;
            float: right;
            clear: both;
            margin-top: 0;
            
            .dropdown_item {
              display: block !important;
              margin-right: 1rem !important;
              width: fit-content;
              float: right;
              min-height: auto;
            }
          }

          &.active {  
            // background-color: #97d5ce;

            .dropdown {
              display: block;

              max-height: 30vh;
              overflow-y: scroll;
              overflow-x: hidden;
            }
          }
        }
      }
    }
  }
}